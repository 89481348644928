<template>
  <ul class="portfolio-intro-list">
    <PortfolioIntroListItem v-for="portfolio in portfolios" :key="portfolio.id" :portfolio="portfolio" />
  </ul>
</template>

<script>
import PortfolioIntroListItem from './PortfolioIntroListItem.vue'

export default {
  components: {
    PortfolioIntroListItem,
  },

  props: {
    portfolios: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style>
.portfolio-intro-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--default-column-width), 1fr));
  grid-gap: var(--spacing-m);
}

.portfolio-intro-list > li > .item {
  border-radius: var(--box-radius);
  border: var(--box-border);
  transition: box-shadow 0.2s ease-in-out;
}

.portfolio-intro-list li > .item:hover {
  box-shadow: 0px 8px 16px -2px #eee;
}
</style>
