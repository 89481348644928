<i18n>
{
  "de": {
    "pageTitle": "Portfolios",
    "portfolios": "Ihre Portfolios",
    "noPortfolios": "Sie haben keine aktiven Portfolios",
    "welcomeTitle": "Willkommen",
    "portfoliosLoading": "Ihre Portfolios werden geladen",
    "addPortfolio": "Portfolio erstellen",
    "importPortfolio": "Portfolio importieren"
  }
}
</i18n>

<template>
  <MainLayout :ready="allPortfoliosReady">
    <template #default>
      <h1 v-if="loggedIn">{{ $t('welcomeTitle') }} {{ username }}!</h1>
      <h2>{{ $t('portfolios') }}</h2>

      <PortfolioIntroList v-if="allPortfolios && allPortfolios.length" :portfolios="allPortfolios" />

      <template v-else>
        <div style="margin: 24px 0">
          {{ $t('noPortfolios') }}
        </div>
        <template v-if="isAdmin">
          <ButtonWrapper>
            <router-link class="button" :to="{ name: 'adminAddPortfolio' }">{{ $t('addPortfolio') }}</router-link>
            <router-link class="button" :to="{ name: 'adminImportPortfolio' }">{{ $t('importPortfolio') }}</router-link>
          </ButtonWrapper>
        </template>
      </template>
    </template>
  </MainLayout>
</template>

<script>
import { mapGetters } from 'vuex'

import MainLayout from '@/pages/layouts/MainLayout.vue'

import PortfolioIntroList from '@/components/intro/PortfolioIntroList.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'

import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'

export default {
  name: 'portfolios',

  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
  ],

  components: {
    ButtonWrapper,
    MainLayout,
    PortfolioIntroList,
  },

  computed: {
    ...mapGetters({
      username: 'account/name',
      email: 'account/email',
      company: 'account/company',
      loggedIn: 'account/loggedIn',
      isAdmin: 'account/admin',
    }),
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>
